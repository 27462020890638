import Web3 from 'web3'

export function Joo3 () {
  return new Web3(Web3.givenProvider || 'http://localhost:8545')
}

export class Joo {
  constructor (_web3=null) {
    this.abi = require('./GenericABI.json')
    if (_web3) {
      this.X = _web3
      
    } else {
      this.X = new Web3(Web3.givenProvider || 'http://localhost:8545')
    }
    this.tract = (tokenAddress) => new this.X.eth.Contract(this.abi, tokenAddress)
  }

  callOrRequest(_walletAddress, transaction, callback, requestMode=false) {
    // make a contract call or request with the given callback
    if (requestMode) {
      return transaction.call.request({from: _walletAddress}, callback)
    } else {
      return transaction.call({from: _walletAddress}).then(callback)
    }
  }

  createGenContract (tokenAddress) {
    return new this.X.eth.Contract(this.abi, tokenAddress);
  }

  balanceOf ( tokenAddress, walletAddress) {
 
    return this.tract(tokenAddress).methods.balanceOf(walletAddress)
  }

  balanceFromConfig ( configs, configname, walletAddress) {
    return this.balanceOf(
                configs[configname]["address"],
                walletAddress)
  }

  getNativeBalance = ( walletAddress ) => {
      return this.X.eth.getBalance(walletAddress)
          
      }

  balanceRequest ( tokenAddress, walletAddress, callback ) {
    return this.callOrRequest( 
      walletAddress, 
      this.balanceOf(tokenAddress, walletAddress),
      callback,
      true)
  }

  balanceOfDeadRequest ( tokenAddress, walletAddress, callback ) {
    return this.callOrRequest( 
      walletAddress, 
      this.balanceOf(tokenAddress, "0x000000000000000000000000000000000000dead"),
      callback,
      true)
  }

  totalSupplyRequest ( tokenAddress, walletAddress, callback ) {
    return this.callOrRequest(
      walletAddress,
      this.tract(tokenAddress).methods.totalSupply(),
      callback,
      true
      )
  }

  approve (tokenAddress, spender, walletAddress, amount, callback = () => {}) {

    return this.tract(tokenAddress).methods.approve(spender, amount).send({from: walletAddress}).then(callback)
  }

  allowance (tokenAddress, spender, walletAddress, callback = () => {}) {

    return this.tract(tokenAddress).methods.allowance(walletAddress, spender).call({from: walletAddress}).then(callback)
  }
}

