
import './App.scss';
import { Dapp } from './multiverse.js';
import React, {useState, useEffect} from 'react';
import Web3 from 'web3';

import {
  Link,
  Outlet
} from "react-router-dom";
import { chainMap, enforceChain } from './tools/ChainTools.js'
import detectEthereumProvider from '@metamask/detect-provider';
import { Token } from './tools/token.js'

const ADDY_ORACLE = "0xCB9F868937aEFEFB70e3A483df667740628b583C"
const ADDY_SCORE = "0xC5F0765d064f971b21Ae810f296C74d9D9413460"
const ADDY_STOCKING = "0x9F458093d25700A6659605A31769775b28590f57"
const ADDY_ROUTER_SPOOKY = "0xF491e7B69E4244ad4002BC14e878a34207E38c29"
const ADDY_WFTM = "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83"
const ADDY_EYE = "0x496e1693a7b162c4f0cd6a1792679cc48ecbcc8d" 

const ADDY_SHOP = "0x0d5cE283f69a7634Fe14Bf1f012d8e0c9484dE79"
const ADDY_SOLUTIONSROUTER = "0xf60cdCBf20997e03CD5242CA560470407f892a82"

var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')
var ABI_ORACLE = require("./deliJS/oracleABI.json")
var ABI_SCORE = require("./deliJS/scoreCheckerABI.json")
var ABI_STOCKING = require("./deliJS/stockingNftABI.json")
var ABI_EYE = require("./tools/GenericABI.json")
var ABI_SHOP = require("./deliJS/shopABI.json")
var oracle = new web3.eth.Contract(ABI_ORACLE,ADDY_ORACLE)
var scoreChecker = new web3.eth.Contract(ABI_SCORE,ADDY_SCORE)
var stockingNFT = new web3.eth.Contract(ABI_STOCKING,ADDY_STOCKING)
var EyeContract = new web3.eth.Contract(ABI_EYE, ADDY_EYE)

var ShopContract = new web3.eth.Contract(ABI_SHOP, ADDY_SHOP)


function getPriceFTM () {
	return oracle.methods.exchangeRateInUSDC(ADDY_WFTM,ADDY_ROUTER_SPOOKY).call()
}

function getPriceEYE () {
	return oracle.methods.eyePriceInUSDCSpooky().call()
}

function getMinEyeBurn () {
	return oracle.methods.minEyeBurnToMint().call()
}

function getMinFTM () {
	return oracle.methods.minFTMtoMint().call()
}

function getScore (nEye, nFtm) {
	return scoreChecker.methods.getScore(nEye, nFtm).call()
}

function mintUsingEYE (nEye) {
	return stockingNFT.methods.mintStockingEyeOnly(nEye).send({from: window.ethereum.selectedAddress})
}

function mintStocking (nEye, nFtm) {
	return stockingNFT.methods.mintStocking(nEye, nFtm).send({from: window.ethereum.selectedAddress, value: nFtm})
}

function getStockingCount () {
	return stockingNFT.methods.balanceOf(window.ethereum.selectedAddress).call()
}

function getEyeBalance () {
	return EyeContract.methods.balanceOf(window.ethereum.selectedAddress).call()
}

function getEyeBurned () {
	return EyeContract.methods.balanceOf("0x000000000000000000000000000000000000dEaD").call()
}

function getEyeInLP () {
	return EyeContract.methods.balanceOf("0xaf6125d25c154cb23a28a1c3dd85b7d424a41d6e").call()
}

function getEyeStakedInPub () {
	return EyeContract.methods.balanceOf("0x2f07769a9e03091e78aa4ddf77aff1e5732b664f").call()
}

function getEyeTotalSupply () {
	return EyeContract.methods.totalSupply().call()
}


function getScoreByTokenId(tokenId) {
	return stockingNFT.methods(getScoreByTokenId(tokenId)).call()
}

export function App() {

    const [isPending, setIsPending] = useState(false)
	const [connected, setConnected] = useState(false)
	const [mmBtnText, setMMBtnText] = useState("Connect")
	const [accounts, setAccounts] = React.useState([]);
	const [currentAccount, setCurrentAccount] = useState("")

	

	// attached to the accountsChanged event listener
	// triggered once manually via connectMM
	function handleNewAccounts(newAccounts) {
		setCurrentAccount(newAccounts);
	}

	// attached to the chainChanged event listener
	function handleChainChange(chainId) {
		enforceChain("Fantom", ()=>{})
	}

	// when triggered, connectMM requests the user connects to the dApp
	// if the user is already connected, or after the user connects,
	// connectMM sets the accounts state to the user's connected accounts,
	// and sets the connected state to true
	const connectMM = () => {
	    window.ethereum
	      .request({ method: 'eth_requestAccounts' })
	      .then(handleAccountsChanged)  
	      .catch((err) => {
			    console.error(err)
		  	})
	}

	const handleAccountsChanged = (accs) => {
	  if (accs.length === 0) {
	    // MetaMask is locked or the user has not connected any accounts
	    console.log('Please connect to MetaMask.');
	  } else if (accs[0] !== currentAccount) {
	    // currentAccount = accs[0];
	    // Do any other work!
	    setCurrentAccount(accs[0])
	    setConnected(true)
	    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId))
	  }
	}

	useEffect(()=>{
		const provider = detectEthereumProvider()
		if (provider) {
		  window.ethereum
		  	.request({method: 'eth_accounts'})
		  	.then(handleAccountsChanged)
		  	.catch((err) => {
		  		// Some unexpected error.
			    // For backwards compatibility reasons, if no accounts are available,
			    // eth_accounts will return an empty array.
			    console.error(err)
		  	})
		} else {
		  console.log('Please install MetaMask!');
		}


	})

  

  // once the user is connected, add the accountsChanged event listener
  // and add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChange);
      
      
      return () => {
        window.ethereum.on('accountsChanged', handleAccountsChanged);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  return (
    <div className="App">
    
      <div className="App__Body">
      	<Outlet />
      </div>
       <button onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
    </div>
  );
}


export function Cosms() {
  return (
    <div className="Cosms">
      cosmogonies - coming soon...
    </div>
  );
}

export function Xmas() {

  return (
    <div className="Xmas">
      <h1>Mythmas</h1>
      <nav>
      	<Link to="/">Truth Home</Link>
      	<Link to="">Mythmas Home</Link>
      	<Link to="stocking">Mint a Stocking</Link>
      	<Link to="workshop">Solution's Workshop</Link>
      	{ //<Link to="fireplace">The Fireplace</Link>
      	}
      </nav>
      <div className="Xmas__Page">
      	<Outlet />
      </div>
    </div>
  );
}


const parseDec18 = (x) => {
		let strX = x.toString()
		let right = "0"
		let left = "0"
		if (strX.length < 18) {
			right = "0".repeat(18 - strX.length) + strX
			left = "0"
		} else {
			right = strX.substring(strX.length - 18)
			left = strX.substring(0,strX.length - 18)
		}
		
		return left + "." + right
	}
export function XmasStockings() {
	const [priceFTM, setPriceFTM] = useState(0)
	const [priceEYE, setPriceEYE] = useState(0)

	const [eyeValue, setEyeValue] = useState(0)
	const [ftmValue, setFtmValue] = useState(0)

	const [minEyeBurn, setMinEyeBurn] = useState(0);
	const [minFTM,setMinFtm] = useState(0);

	const [eyeOnlyMintText, setEyeOnlyMintText] = useState("APPROVE")
	
	const [stockingCount, setStockingCount] = useState(0)
	const [eyeBalance, setEyeBalance] = useState(0)

	const [eyeBurned, setEyeBurned] = useState(0)
	const [eyeInLP, setEyeInLP] = useState(0)
	const [eyeStakedInPub, setEyeStakedInPub] = useState(0)
	var eyeInput = React.createRef();
	var ftmInput = React.createRef();

	const [eyeTotalSupply, setEyeTotalSupply] = useState(0)

	const [inputScore, setInputScore] = useState(0);

	getPriceFTM().then((result) => {
      	setPriceFTM(result)
      	console.log(result)
      })
	getPriceEYE().then((result) => {
		setPriceEYE(result)
		console.log(result)
	})
	getMinEyeBurn().then((result) => {
		setMinEyeBurn(result)
		console.log(result)
	})
	getMinFTM().then((result) => {
		setMinFtm(result)
		console.log(result)
	})

	getStockingCount().then((result) => {
		setStockingCount(result)
	})

	getEyeBalance().then((result) => {
		setEyeBalance(result)
	})

	getEyeTotalSupply().then((result) => {
		setEyeTotalSupply(result)
	})

	getEyeBurned().then((result) => {
		setEyeBurned(result)
	})

	getEyeInLP().then((result) => {
		setEyeInLP(result)
	})

	getEyeStakedInPub().then((result) => {
		setEyeStakedInPub(result)
	})

	const onScoreCheck = () => {
		getScore(toDec18(eyeInput.current.value), toDec18(ftmInput.current.value)).then((result) => {
			setInputScore(result)
		})
	}
	const trySetup = () => {
		setEyeValue(eyeInput.current.value * parsePriceEYE())
		setFtmValue(ftmInput.current.value * parsePriceFTM())
		onScoreCheck()
	}

	const eyeOnlyMint = () => {
		if (eyeOnlyMintText == "APPROVE" ) {
			EyeContract.methods.allowance(window.ethereum.selectedAddress, ADDY_STOCKING).call().then( (result) => {
				if (Number(parseDec18(result.toString())) < eyeInput.current.value) {
					EyeContract.methods.approve(ADDY_STOCKING, toDec18(eyeInput.current.value)).send({from: window.ethereum.selectedAddress}).then( (result) => {
						setEyeOnlyMintText("MINT")
					})
				} else {
					setEyeOnlyMintText("MINT")
				}
			})
		} else {
			mintUsingEYE(toDec18(eyeInput.current.value)).then((result) => {
				getStockingCount().then((res) => {
					setStockingCount(res)
				})
				getEyeBalance().then((result) => {
					setEyeBalance(result)
				})
			})
		}
		
		
	}

	const mint = () => {
		if (eyeOnlyMintText == "APPROVE" ) {
			EyeContract.methods.allowance(window.ethereum.selectedAddress, ADDY_STOCKING).call().then( (result) => {
				if (Number(parseDec18(result.toString())) < eyeInput.current.value) {
					EyeContract.methods.approve(ADDY_STOCKING, toDec18(eyeInput.current.value)).send({from: window.ethereum.selectedAddress}).then( (result) => {
						setEyeOnlyMintText("MINT")
					})
				} else {
					setEyeOnlyMintText("MINT")
				}
			})
		} else {
			mintStocking(toDec18(eyeInput.current.value), toDec18(ftmInput.current.value)).then((result) => {
				getStockingCount().then((res) => {
					setStockingCount(res)
				})
				getEyeBalance().then((result) => {
					setEyeBalance(result)
				})
			})
		}
		
		
	}

	const parsePriceFTM = () => {
		let strPrice = priceFTM.toString()
		let right = strPrice.substring(strPrice.length - 6)
		let left = strPrice.substring(0,strPrice.length - 6)
		return left + "." + right
	}

	const parsePriceEYE = () => {
		let strPrice = priceEYE.toString()
		let right = "0"
		let left = "0"
		if (strPrice.length <= 6) {
			right = "0".repeat(6 - strPrice.length) + strPrice
			left = "0"
		} else {
			right = strPrice.substring(strPrice.length - 6)
			left = strPrice.substring(0,strPrice.length - 6)
		}
		
		return left + "." + right
	}

	
	const toDec18 = (x) => {
		const strX = x.toString()
		const decLoc = strX.indexOf(".")
		if (decLoc == -1 ) {
			console.log(strX + "0".repeat(18))
			return strX + "0".repeat(18)
		} else {
			let left = strX.substring(0,decLoc)
			let right = strX.substring(decLoc+1)
			let pad = "0".repeat(18 - right.length)
			return left + right + pad
		}
	}
	const readableSupply = () => {
		return Number(parseDec18(eyeTotalSupply).substring(0,parseDec18(eyeTotalSupply).indexOf("."))) - 
					Number(parseDec18(eyeBurned).substring(0,parseDec18(eyeBurned).indexOf(".")))
	}
	const readableSupplyLP = () => {
		return Number(parseDec18(eyeInLP).substring(0,parseDec18(eyeInLP).indexOf("."))) 
	}
	const readableSupplyPub = () => {
		return Number(parseDec18(eyeStakedInPub).substring(0,parseDec18(eyeStakedInPub).indexOf(".")))
	}

	return(
		<div className="Stockings">
			<h2>Mint a Stocking</h2>
			<div>
				<div className="tprice">
					<Token name="eye" />
					<span>{"$" + parsePriceEYE()}</span>
				</div>
				<div className="tprice">
					<Token name="ftm"/>
					<span>{"$" + parsePriceFTM()}</span>
				</div>
			</div>
			<div className="EyeStats">
			<h2>EYE stats</h2>
				<div>
					<span>Total Supply: {readableSupply()}</span>
				</div>
				<div>
					<span>Supply In LP: {readableSupplyLP()} ({Math.round(100*readableSupplyLP()/readableSupply())}%)</span>
				</div>
				<div>
					<span>Staked in Pub: {readableSupplyPub()} ({Math.round(100*readableSupplyPub()/readableSupply())}%)</span>
				</div>
				<div>
					<span>
					Supply In Wallets: {readableSupply() - readableSupplyLP() - readableSupplyPub()}
					</span>
				</div>
			</div>
			<div className="EyeStats YourStats">
			<h2>your stats</h2>
				<div>
					<span>You have</span>
					<span> {stockingCount} Stockings</span>
				</div>
				<div>
					<span>You have</span>
					<span> {parseDec18(eyeBalance).substring(0,parseDec18(eyeBalance).indexOf("."))} EYE</span>
				</div>
				
		
			</div>
			<div className="MintingBox">
				<div className="EyeStats">
					<h3>Minimums</h3>
					<div className="miniInfo">
						<span>Minimum EYE Burn</span>
						<span> {parseDec18(minEyeBurn).substring(0,4)} EYE</span>
					</div>
					<div className="miniInfo">
						<span>Minimum FTM Contribution</span>
						<span> {parseDec18(minFTM).substring(0,4)} FTM</span>
					</div>
				</div>
				<div className="EyeStats">
				<h3>Input Amounts</h3>
				<div>
				<p>EYE Amount to Burn</p>
				<input 
					className="eyeInput" 
					type="text" 
					placeholder={parseDec18(minEyeBurn).substring(0,4)} 
					ref={eyeInput}
					defaultValue={parseDec18(minEyeBurn).substring(0,4)} /> 
					</div>
				<div>
				<p>FTM Amount to Contribute</p>
				<input className="ftmInput" type="text" 
					placeholder="1" ref={ftmInput} defaultValue={parseDec18(minFTM).substring(0,4)} />
					</div> 
				<button onClick={trySetup}>Check Score</button>
				
				</div>
				<div  className="EyeStats">
					<h3>Value Ratio & Score</h3>
					<p>The ratio must be between 0.05 and 1</p>
					<div className="equation">
						<div className="frac">
							<div className="numer">
								$ {eyeValue.toFixed(2)}
							</div>
							<div className="denom">
								$ {ftmValue.toFixed(2)}
							</div>
						</div>
						<div className="rhs">
							= {(ftmValue !== 0) ? (eyeValue/ftmValue).toFixed(2) : 0}
						</div>
					</div>
					<div className="miniInfo">
						<span>Score</span>
						<span> {Number(parseDec18(inputScore)).toFixed(2)}</span>
					</div>
					<button onClick={mint}>{eyeOnlyMintText}</button>
				</div>
				
				{
					// <input type="text" ref={ftmInput} /> 
					// <button onClick={onScoreCheck}>Check Score</button>
					// <div className="score">{parseDec18(inputScore).substring(0,4)}</div>
				}
			</div>
		</div>
		);
}

const mythmasDate = new Date(Date.UTC(2021,11,30,5,0,0,0))
const nowTime = Date.now()
export function XmasLander() {
	
	const [rightNow, setRightNow] = useState(nowTime)

	const getTimeLeft = () => {
		console.log(rightNow)
		return (
			Math.floor((mythmasDate.getTime() - rightNow)/(1000 * 60 * 60)) 
			)
	}

	return(
		<div className="XmasLander">
			<h2>Happy Holidays, Degen</h2>
			<p>Stockings can be minted using EYE and Fantom.</p>
			<p>The max score has been reduced from 420 to 137.</p>
			<p>The Value of EYE Burned must be between 5% and 100% of the Value of FTM contributed.</p>
			<p>The Stocking Score is 5 * Value in EYE + Value in FTM</p>
			<p>The FTM contributed is sent to the Workshop.</p>
			<p>When the Workshop balance is greater than the threshold, users can trigger the StuffAllStockings function.</p>
			<p>Any excess FTM over the threshold goes to the function caller.</p>
			<p>The Stocking Score divided by the Total Score, determines the distribution of rewards to that Stocking.</p>
			<p>Stockings will be used for all future Mythmas Events.</p>
		</div>
		);
}



export function Workshop() {
	const [shopBalance, setShopBalance] = useState(0)
	const [balanceThresh, setBalanceThresh] = useState(0)
	const [gasEstimate, setGasEstimate] = useState(0)

	const [isStuffable, setIsStuffable] = useState(false)

	const [rewards, setRewards] = useState(0)

	const getWorkshopInfo = () => {
		web3.eth.getBalance(ADDY_SHOP).then((res) => {
			setShopBalance(res)
			ShopContract.methods.balanceThreshold().call().then((res) => {
				setBalanceThresh(res)
				setIsStuffable((Number(parseDec18(shopBalance)) > Number(parseDec18(res)))) 
				web3.eth.getGasPrice().then((result) => {
					let gasprice = result;
					ShopContract.methods.stuffAllStockings()
						.estimateGas ({from: window.ethereum.selectedAddress})
						.then((result) => {
							setGasEstimate(gasprice * result)
						})

					})
				web3.eth.getBalance(ADDY_SOLUTIONSROUTER).then((res) => {
					setRewards(res)
				})
			})
		})
	}
	useEffect(() => {
		getWorkshopInfo()
	})

	const stuff = () => {
		ShopContract.methods.stuffAllStockings().send({from: window.ethereum.selectedAddress}).then((res) => {
			getWorkshopInfo()
		})
	}

	const update = () => {
		getWorkshopInfo()
	}

	return (
		<div className="XmasLander">
			<h2>Solution's Workshop</h2>
			<div>
				<div className="miniInfo">
					<span>In Reward Distributer</span>
					<span>{Number(parseDec18(rewards)).toFixed(3)} FTM</span>
				</div>
				<div className="miniInfo">
					<span>Workshop Balance</span>
					<span>{Number(parseDec18(shopBalance)).toFixed(3)} FTM</span>
				</div>
				<div className="miniInfo">
					<span>Threshold</span>
					<span>{Number(parseDec18(balanceThresh)).toFixed(3)} FTM</span>
				</div>
			</div>
			<div>
				<div className="miniInfo">
					<span>Bounty</span>
					<span>{(isStuffable) ? ((Number(parseDec18(shopBalance)) - Number(parseDec18(balanceThresh)))*0.99).toFixed(3) : 0} FTM</span>
				</div>
				<div className="miniInfo">
					<span>Estimated Gas Cost</span>
					<span>{Number(parseDec18(gasEstimate)).toFixed(3)} FTM</span>
				</div>
				<div className="miniInfo">
					<span>Estimated Stuffer Profit</span>
					<span>{(isStuffable) ? ((Number(parseDec18(shopBalance)) - Number(parseDec18(balanceThresh)))*0.99).toFixed(3) - Number(parseDec18(gasEstimate)).toFixed(3): 0} FTM</span>
				</div>
			</div>
			<div className="button-only">
				<button onClick={update}>Update Workshop</button>
			</div>
			<div className="button-only">
				<button onClick={stuff} disabled={!(isStuffable)}>Stuff All Stockings</button>
			</div>
			
		</div>
	)
}


export function Lander() {
	return (
    <div className="Lander">
    	<div className="Lander__Focus">
    		<h1>Truth</h1>
    	</div>
    	<div className="Lander__SubFocus">
      		<Link to="mythmas">mythmas</Link>
      		<Link to="alchemists">alchemists</Link>
      		{//<Link to="cosmogonies">cosmogonies</Link>
      	}
      	</div>
    </div>
  );

}