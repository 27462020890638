import React from 'react';
import { Pu } from '../tools/Pu.js'
import { Joo } from '../tools/JooishWeb3.js'
import { FarmgodCore } from '../deliJS/farmgod-core.js'
import {formatTokenBalance} from '../tools/tokenUtilities'
import { Token } from '../tools/token.js'
import { suggestion } from '../tools/TokenSuggestion.js'

export const Wallet = (props) => {
  
  var web3 = props.web3
  const getBalanceStateString = (tokenName, isSet = true ) => {
    const prefix = (!isSet) ? "w" : "setW"
    const meat = "alletBalance"
    const suffix = "_" + tokenName
    return prefix + meat + suffix  
  }

  const getSetBalanceStateString = (cbvar, tokenName ) => {
    return getBalanceStateString(tokenName) + "(" + cbvar + ")" 
  }

  const getTotalSupplyStateString = (tokenName, isSet = true ) => {
    const prefix = (!isSet) ? "t" : "setT"
    const meat = "otalSupply"
    const suffix = "_" + tokenName
    return prefix + meat + suffix  
  }

  const getSetTotalSupplyStateString = (cbvar, tokenName ) => {
    return getTotalSupplyStateString(tokenName) + "(" + cbvar + ")" 
  }

  const getPoolBalanceStateString = (tokenName, chefName, isSet = true ) => {
    const prefix = (!isSet) ? "p" : "setP"
    const meat = "oolBalance"
    const suffix = "_" + tokenName + "_" + chefName
    return prefix + meat + suffix  
  }

const getSetPoolBalanceStateString = (cbvar, tokenName, chefName ) => {
    return getPoolBalanceStateString(tokenName, chefName) + "(" + cbvar + ")" 
  }

  const getDeadBalanceStateString = (tokenName, isSet = true ) => {
    const prefix = (!isSet) ? "d" : "setD"
    const meat = "eadBalance"
    const suffix = "_" + tokenName
    return prefix + meat + suffix  
  }

const getSetDeadBalanceStateString = (cbvar, tokenName ) => {
    return getDeadBalanceStateString(tokenName) + "(" + cbvar + ")" 
  }

// walletBalance
const [walletBalance_echo, setWalletBalance_echo] = React.useState(0)

const [walletBalance_deflaton, setWalletBalance_deflaton] = React.useState(0)
const [walletBalance_gigadeflaton, setWalletBalance_gigadeflaton] = React.useState(0)
const [walletBalance_distributon, setWalletBalance_distributon] = React.useState(0)

const [walletBalance_munny, setWalletBalance_munny] = React.useState(0)
const [walletBalance_eye, setWalletBalance_eye] = React.useState(0)

const [walletBalance_gdeli, setWalletBalance_gdeli] = React.useState(0)
const [walletBalance_acre, setWalletBalance_acre] = React.useState(0)
const [walletBalance_yomopu, setWalletBalance_yomopu] = React.useState(0)
const [walletBalance_salt, setWalletBalance_salt] = React.useState(0)
const [walletBalance_water, setWalletBalance_water] = React.useState(0)

const [walletBalance_tapioca, setWalletBalance_tapioca] = React.useState(0)

// totalSupply
const [totalSupply_echo, setTotalSupply_echo] = React.useState(0)

const [totalSupply_gdeli, setTotalSupply_gdeli] = React.useState(0)
const [totalSupply_acre, setTotalSupply_acre] = React.useState(0)
const [totalSupply_yomopu, setTotalSupply_yomopu] = React.useState(0)
const [totalSupply_salt, setTotalSupply_salt] = React.useState(0)
const [totalSupply_water, setTotalSupply_water] = React.useState(0)

const [totalSupply_deflaton, setTotalSupply_deflaton] = React.useState(0)
const [totalSupply_gigadeflaton, setTotalSupply_gigadeflaton] = React.useState(0)
const [totalSupply_distributon, setTotalSupply_distributon] = React.useState(0)

const [totalSupply_munny, setTotalSupply_munny] = React.useState(0)
const [totalSupply_eye, setTotalSupply_eye] = React.useState(0)

const [totalSupply_tapioca, setTotalSupply_tapioca] = React.useState(0)

// maxSupply
const [maxSupply_echo, setMaxSupply_echo] = React.useState(0)

const [maxSupply_gdeli, setMaxSupply_gdeli] = React.useState(0)
const [maxSupply_acre, setMaxSupply_acre] = React.useState(0)
const [maxSupply_yomopu, setMaxSupply_yomopu] = React.useState(0)
const [maxSupply_salt, setMaxSupply_salt] = React.useState(0)
const [maxSupply_water, setMaxSupply_water] = React.useState(0)

const [maxSupply_deflaton, setMaxSupply_deflaton] = React.useState(0)
const [maxSupply_gigadeflaton, setMaxSupply_gigadeflaton] = React.useState(0)
const [maxSupply_distributon, setMaxSupply_distributon] = React.useState(0)

const [maxSupply_munny, setMaxSupply_munny] = React.useState(0)
const [maxSupply_eye, setMaxSupply_eye] = React.useState(0)

const [maxSupply_tapioca, setMaxSupply_tapioca] = React.useState(0)

// deadBalance
const [deadBalance_echo, setDeadBalance_echo] = React.useState(0)

const [deadBalance_gdeli, setDeadBalance_gdeli] = React.useState(0)
const [deadBalance_acre, setDeadBalance_acre] = React.useState(0)
const [deadBalance_yomopu, setDeadBalance_yomopu] = React.useState(0)
const [deadBalance_salt, setDeadBalance_salt] = React.useState(0)
const [deadBalance_water, setDeadBalance_water] = React.useState(0)

const [deadBalance_deflaton, setDeadBalance_deflaton] = React.useState(0)
const [deadBalance_gigadeflaton, setDeadBalance_gigadeflaton] = React.useState(0)
const [deadBalance_distributon, setDeadBalance_distributon] = React.useState(0)

const [deadBalance_munny, setDeadBalance_munny] = React.useState(0)
const [deadBalance_eye, setDeadBalance_eye] = React.useState(0)

const [deadBalance_tapioca, setDeadBalance_tapioca] = React.useState(0)

// poolBalance
const [poolBalance_echo_acremaker, setPoolBalance_echo_acremaker] = React.useState(0)

const [poolBalance_gdeli_acremaker, setPoolBalance_gdeli_acremaker] = React.useState(0)
const [poolBalance_acre_acremaker, setPoolBalance_acre_acremaker] = React.useState(0)
const [poolBalance_yomopu_puchef, setPoolBalance_yomopu_puchef] = React.useState(0)
const [poolBalance_yomopu_bobachef, setPoolBalance_yomopu_bobachef] = React.useState(0)

const [poolBalance_deflaton, setPoolBalance_deflaton] = React.useState(0)
const [poolBalance_gigadeflaton, setPoolBalance_gigadeflaton] = React.useState(0)
const [poolBalance_distributon, setPoolBalance_distributon] = React.useState(0)

const [poolBalance_munny, setPoolBalance_munny] = React.useState(0)
const [poolBalance_eye, setPoolBalance_eye] = React.useState(0)

const [poolBalance_tapioca, setPoolBalance_tapioca] = React.useState(0)

const PU = {
  "acremaker": new Pu(web3, "acremaker"),
  "puchef": new Pu(web3, "puchef"),
  "bobachef": new Pu(web3, "bobachef")
}


const buildBatchOfBalanceRequests = () => {
  var j = new Joo(web3)
  var batch = new web3.BatchRequest();

  Object.keys(FarmgodCore).forEach(tokenName => {
    batch.add(j.balanceRequest(
      FarmgodCore[tokenName]["address"],
      window.ethereum.selectedAddress,
      (x, res) => {
        eval(getSetBalanceStateString(res, tokenName))
      }
      ))
    batch.add(j.totalSupplyRequest(
      FarmgodCore[tokenName]["address"],
       window.ethereum.selectedAddress,
      (x, res) => {
        eval(getSetTotalSupplyStateString(res, tokenName))
      }
      ))
    batch.add(j.balanceOfDeadRequest(
      FarmgodCore[tokenName]["address"],
       window.ethereum.selectedAddress,
      (x, res) => {
        eval(getSetDeadBalanceStateString(res, tokenName))
      }
      ))

    if (typeof FarmgodCore[tokenName]["pools"] != 'undefined') {
      FarmgodCore[tokenName]["pools"].forEach(chefPidPair => {
         // [{"chef":"puchef", "pid":0}, {"chef":"bobachef","pid":0}]
         batch.add(
          PU[chefPidPair["chef"]].getPoolBalance(chefPidPair["pid"], 
            (x, res) => {
              console.log(res)
              eval(getSetPoolBalanceStateString(res[0], tokenName, chefPidPair["chef"]))
            })
         )
      })
    }
  })

  batch.execute()
}

const hasPools = (pools) => {
  console.log(pools)
  console.log(typeof pools != 'undefined')
  return typeof pools != 'undefined'
}

const hasChef = (chef, pools) => {
  let out = false
  if (hasPools(pools)) {
    
    pools.forEach(pool => {
      if (pool["chef"] == chef) {
        out = true
      }  
    }) 
  }
  return out
}

const thePools = (tokenName) => FarmgodCore[tokenName]["pools"]
const hasThePools = (tokenName) => hasPools(thePools(tokenName))
const hasTheChef = (chef, tokenName) => {
  console.log(chef)
  console.log(tokenName)
  return (hasThePools(tokenName)) ? hasChef(chef, thePools(tokenName)) : false
}
const getFormattedPoolBalance = (chef, tokenName) => {
  return ( hasTheChef( chef, tokenName ) ) ?  
        formatTokenBalance(
          FarmgodCore[tokenName]["decimals"], 
          eval(getPoolBalanceStateString(
            tokenName, 
            chef, 
            false))) : "--"
}

const getPCTExisting = (tokenName) => {
  let x = eval(getTotalSupplyStateString(tokenName, false))
  let y = FarmgodCore[tokenName]["max"]

  x = x / (10 ** FarmgodCore[tokenName]["decimals"])
  return formatTokenBalance(0, 100* x / Number(y))
}
const getExisting = (tokenName, format = true) => {
  let x = eval(getTotalSupplyStateString(tokenName, false))
  let y = eval(getDeadBalanceStateString(tokenName, false))

  x = x / (10 ** FarmgodCore[tokenName]["decimals"])
  y = y / (10 ** FarmgodCore[tokenName]["decimals"])
  return (format) ? formatTokenBalance(0, x - y) : x - y
}

const getPCTBurnedOfCurrent = (tokenName) => {
  let y = eval(getTotalSupplyStateString(tokenName, false))
  let x = eval(getDeadBalanceStateString(tokenName, false))

  x = x / (10 ** FarmgodCore[tokenName]["decimals"])
  y = y / (10 ** FarmgodCore[tokenName]["decimals"])
  return formatTokenBalance(0, 100* x / Number(y))
}
 const getTotalBalance = (tokenName, format = true) => {
  let x = eval(getBalanceStateString(tokenName, false))
  let y = ()=>{
    try{return eval(getPoolBalanceStateString(
            tokenName, 
            "acremaker", 
            false))} catch (error) {return 0}
  }
  let z = ()=>{
    try{return eval(getPoolBalanceStateString(
            tokenName, 
            "puchef", 
            false))} catch (error) {return 0}
  }
  let zz = ()=>{
    try{return eval(getPoolBalanceStateString(
            tokenName, 
            "bobachef", 
            false))} catch (error) {return 0}
  }



  return (format) ? formatTokenBalance(FarmgodCore[tokenName]["decimals"], x + y() + z() + zz()) :  x + y() + z() + zz()
}

const getYourPCTExistingSupply = (tokenName) => {
  return formatTokenBalance(FarmgodCore[tokenName]["decimals"], 100 * getTotalBalance(tokenName, false) / getExisting(tokenName, false) )
}


  const [triggered, setTriggered] = React.useState(false)
  React.useEffect(() => {
    if (props.connected && !triggered) {
      buildBatchOfBalanceRequests()
      setTriggered(true)
    }
  }, [props.connected, triggered])
  const [isOn, setIsOn] = React.useState(false);
  const toggleIsOn = () => {
    if (isOn) {
      setIsOn(false)
    } else {
      setIsOn(true)
    }
  }


  return (
  <div className={"wallet-container isOn--" + isOn}>
    <button onClick={toggleIsOn}>Toggle Wallet Details</button>
      <table>
      <tr className="headhead">
      <th ></th>
      <th className="bal" colspan="6">Your Balances</th>
      <th className="sup" colspan="6">Supply</th>
      <th className="tdtext" colspan="5">Context</th>
      
      
      </tr>
      <tr>
        <th className="tdtoken strong"></th>
        <th className="bal  strong special">Total Balance</th>
        <th className="bal pct">Your % of Existing Supply</th>
        <th className="bal  strong">Wallet Balance</th>
        <th className="bal">Farming Acres</th>
        <th className="bal">Farming Yomopu</th>
        <th className="bal">Staked on Boba</th>

        <th className="sup special">Existing Supply</th>
        
        <th className="sup">Max Supply</th>
        <th className="sup">Current Supply</th>
        <th className="sup pct">% Current</th>
        <th className="sup">Burned Supply</th>
        <th className="sup pct">% Burned</th>

        
         

        <th className="tdtext smallaf">Contract</th>
        <th className="tdtext">Scope</th>
        <th className="tdtext">Type</th>
        <th className="tdtext">Archetype</th>
        <th className="tdtext smallaf r">Tax</th>
        

       
      </tr>
        {Object.keys(FarmgodCore).map((tokenName, index) => 
    <tr key={index} className={"tdtokenrow tdtokenrow--" + tokenName}>
      <td className="tdtoken strong" onClick={
        suggestion(
          FarmgodCore[tokenName]["address"], 
          FarmgodCore[tokenName]["name"], 
          FarmgodCore[tokenName]["decimals"], 
          FarmgodCore[tokenName]["staticimg"])
      }><Token name={tokenName} /></td>
      <td className="bal strong special">{getTotalBalance(tokenName)}</td>
      <td className="bal pct">{getYourPCTExistingSupply(tokenName)} %</td>
      <td className="bal strong">{formatTokenBalance(FarmgodCore[tokenName]["decimals"], eval(getBalanceStateString(tokenName, false)))}</td>
      <td className="bal">{getFormattedPoolBalance("acremaker", tokenName)}</td>
      <td className="bal">{getFormattedPoolBalance("puchef", tokenName)}</td>
      <td className="bal">{getFormattedPoolBalance("bobachef", tokenName)}</td>
      <td className="sup special">{getExisting(tokenName)}</td>
      <td className="sup">{formatTokenBalance(0,FarmgodCore[tokenName]["max"])}</td>
      <td className="sup">{formatTokenBalance(FarmgodCore[tokenName]["decimals"], eval(getTotalSupplyStateString(tokenName, false)))}</td>
      <td className="sup pct">{getPCTExisting(tokenName)} %</td>
      <td className="sup">{formatTokenBalance(FarmgodCore[tokenName]["decimals"], eval(getDeadBalanceStateString(tokenName, false)))}</td>
      <td className="sup pct">{getPCTBurnedOfCurrent(tokenName)} %</td>
      
      
      <td className="tdtext smallaf"><a href={"https://ftmscan.com/token/" + FarmgodCore[tokenName]["address"]} target="_blank">Read</a></td>
      <td className="tdtext">{FarmgodCore[tokenName]["scope"]}</td>
      <td className="tdtext">{FarmgodCore[tokenName]["type"]}</td>
      
      <td className="tdtext">{FarmgodCore[tokenName]["archetype"]}</td>
      <td className="tdtext smallaf r">{FarmgodCore[tokenName]["tax"]}</td>
      
      
    
    </tr>

      )}</table>
        </div> )
}
