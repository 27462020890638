 export function suggestion (a, s, d, i) {



     return function () {
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                  address: a, // The address that the token is at.
                  symbol: s, // A ticker symbol or shorthand, up to 5 chars.
                  decimals: d, // The number of decimals in the token
                  image: i, // A string url of the token logo
                },
              },
            });

            if (wasAdded) {
              console.log('Thanks for your interest!');
            } else {
              console.log('Your loss!');
            }
          } catch (error) {
            console.log(error);
          } 
     }
          
 
}