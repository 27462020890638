import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'

import { truths } from './deliJS/farmgod-core.js'
import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'


import { Wallet } from './deliJS/wallet.js'

 import {Footer } from './deliJS/footer.js'


export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var alchemists = new web3.eth.Contract(truths["alchemists"]["abi"], truths["alchemists"]["address"])

const Alchemist = (props) => (<div className="alchemist"><img src={props.img} /></div>)
const [alcaImg, setAlcaImg] = React.useState("");
const [alcaName, setAlcaName] = React.useState("");
const [alcaDesc, setAlcaDesc] = React.useState("");

const [alcaNum, setAlcaNum] = React.useState(1)
const [alcaOwner, setAlcaOwner] = React.useState("")

const letItRip = () => {
  getAlca(alcaNum) 
}


const getAlca = (i) => {
  var batch = new web3.BatchRequest();
  batch.add(alchemists.methods.tokenURI(i).call.request(
        {from: window.ethereum.selectedAddress},
      
        (err, res) => {
          var x = JSON.parse(atob(res.substring(29)))
          setAlcaImg(x.image)  
        }))
  batch.add(alchemists.methods.ownerOf(i).call.request(
      {from: window.ethereum.selectedAddress},
      (err, res) => {
          if (err) {
            setAlcaOwner (false)
          } else {
            setAlcaOwner(res)  
          }
          
        }
    ))
  batch.execute();
}

React.useEffect(() => {

  getAlca(alcaNum)
}, [alcaNum])

const mint = (id) => {
  if (id > 0 && id < 3333) {
    alchemists.methods.claimFree(id).send({from: window.ethereum.selectedAddress})
      .then(
        (res)=> {
          getAlca(alcaNum)
        })
  } else {
    alchemists.methods.claimPaid(id).send({from: window.ethereum.selectedAddress, value: web3.utils.toWei("10","ether")})
      .then(
        (res)=> {
          getAlca(alcaNum)
        })
  }
  
}

const tokenId = React.useRef();

  return (
    <div className={"App-wrapper " + " connected--" + connected}>

   
      <button disabled={isDisabled} onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
  
      <h2>Truth - Prelude Mint - The Alchemists</h2>
      <div>
        <div>
          <input ref={tokenId} />
          <button onClick={() => {

          setAlcaNum(tokenId.current.value) }}>Check</button>
        </div>
        <div>
          <h1>Alchemist #{alcaNum}</h1>
          <div>
          { 
            (!alcaOwner) ? 
              <button onClick={() => {mint(alcaNum)}}>
                {(alcaNum >= 3333) ? "Mint for 10 FTM": "Mint for Free"}
              </button> : "Owned by: " + alcaOwner}
          </div>
        </div>
        <Alchemist img={alcaImg}/>
        <div>
          
          <button onClick={() => {
          let x = alcaNum
          setAlcaNum(--x) }}>Back</button>
          
          <button onClick={() => {
          let x = alcaNum
          setAlcaNum(++x) }}>Next</button>
          </div>
      </div>
      <Footer />
    </div>

  );
}

