import React from 'react';
import ReactDOM from 'react-dom';
import { App, Xmas, Workshop, Lander, XmasStockings, XmasLander } from './App';
import { Dapp } from './multiverse.js';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";



ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
      	<Route index element={<Lander />} />
      	<Route path="mythmas" element={<Xmas />}>
      		<Route index element={<XmasLander />} />
      		<Route path="stocking" element={<XmasStockings />} />
      		<Route path="workshop" element={<Workshop />} />
      	</Route>
        <Route path="alchemists" element={<Dapp />} />
        {
        	// <Route path="cosmogonies" element={<Cosms />} />
        }
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)



